// @flow

import React from 'react';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import Link from '@design-system/component-library/src/components/Link';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconExternalLinkRegular from '@design-system/component-library/src/components/Icon/lib/IconExternalLinkRegular';
import classnames from 'classnames';
import BaseContainer from '../BaseContainer/BaseContainer';
import { isProd, isTest } from '../../helpers';
import BulletinEditor from './BulletinEditor';
import Notifications from '../../components/Notifications/Notifications';
import ServicePlansEditor from './ServicePlansEditor';
import TopNavigation from '../navigation/TopNavigation';
import SharedPhonebook from '../enterprises/EnterpriseSettings/SharedPhonebook';
import { isMultiEnterpriseAdmin } from '../../utils/accessRightUtils';
import AdminExport from './AdminExport';
import ROBMExport from './ROBMExport';
import styles from './AdminUi.module.scss';

const getRapiLinkDestination = () => {
  if (isProd()) {
    return 'https://rapi-admin-ui.csf.elisa.fi/';
  }
  if (isTest()) {
    return 'https://rapi-admin-ui-test.csf.elisa.fi/';
  }
  return 'https://rapi-admin-ui-dev.csf.elisa.fi/';
};

export const AdminUi = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.currentUser);

  return (
    <BaseContainer
      header={
        <>
          <TopNavigation onClickTutorial={() => {}} />
          <Notifications
            className={styles.notifications}
            tags={[
              'bulletin-create-success',
              'bulletin-create-failure',
              'bulletin-update-success',
              'bulletin-update-failure',
              'bulletin-delete-success',
              'bulletin-delete-failure',
              'phonebook-create-success',
              'phonebook-create-failure',
              'phonebook-update-success',
              'phonebook-update-failure',
              'phonebook-delete-success',
              'phonebook-delete-failure'
            ]}
          />
        </>
      }
    >
      <div
        id="adminui-page"
        className={classnames(styles['adminui-page'], {
          [styles['hide-shared-phonebook']]:
            !(currentUser.featureFlags || []).includes('FEATURE-SHARED-PHONEBOOK-ENABLED') ||
            !isMultiEnterpriseAdmin(currentUser)
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          linkStyle="link-button"
          buttonColor="linkblue"
          buttonSize="m"
          linkTarget="_blank"
          linkHref={getRapiLinkDestination()}
          className={styles['rapi-admin-ui-button']}
        >
          {t('adminui.rapiAdminUi')} <IconExternalLinkRegular />
        </Link>
        <Accordion id="adminui-accordion" className={styles.accordion}>
          <AccordionItem id="adminui-accordion1" heading={t('adminui.bulletinTitle')}>
            <BulletinEditor />
          </AccordionItem>
          <AccordionItem id="adminui-accordion2" heading={t('adminui.servicePlanTitle')}>
            {isMultiEnterpriseAdmin(currentUser) ? <ServicePlansEditor /> : null}
          </AccordionItem>
          <AccordionItem id="adminui-accordion3" heading={t('adminui.sharedPhonebookTitle')}>
            {isMultiEnterpriseAdmin(currentUser) ? <SharedPhonebook /> : null}
          </AccordionItem>
          <AccordionItem id="adminui-accordion4" heading={t('adminui.adminExportTitle')}>
            {isMultiEnterpriseAdmin(currentUser) ? <AdminExport /> : null}
          </AccordionItem>
          <AccordionItem id="adminui-accordion5" heading={t('adminui.robmExport.sectionTitle')}>
            {isMultiEnterpriseAdmin(currentUser) ? <ROBMExport /> : null}
          </AccordionItem>
        </Accordion>
      </div>
    </BaseContainer>
  );
};

export default AdminUi;
