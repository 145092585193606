// @flow

import * as R from 'ramda';
import React, { type Element, useEffect, useState } from 'react';
import { CancelToken } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Combobox from '@design-system/component-library/src/components/Combobox';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useFormContext } from 'react-hook-form';
import directoryOperations from '../../../../../../ducks/entities/directory/directoryOperations';
import { isValidAddressNumber } from '../../../../../../utils/validationUtils';
import { getServiceName } from '../forwardingField/ForwardingUtils';
import styles from './SearchInternalDestination.module.scss';

const MAX_SEARCH_RESULTS = 20;

export type PropsT = {|
  field: string,
  currentAddressNumber: string,
  handleOnTargetSelected: (string, string) => void,
  errors?: *
|};

let requestDestinationsCancelTokenSource;
export const SearchInternalDestination = (props: PropsT): Element<'div'> => {
  const { handleOnTargetSelected, errors, field, currentAddressNumber } = props;

  const { setValue, watch } = useFormContext();

  const values = watch();
  const [searchResults, setSearchResults] = useState<[]>([]);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const currentUser = useSelector(state => state.currentUser);
  const enterpriseId = R.path(['currentEnterprise', 'id'], currentUser) || '';
  const dispatch = useDispatch();

  const addCurrentSelectedValueToSearchResults = async filteredResults => {
    const currentSelectedValue = filteredResults.find(
      result => result.value === values[field].value
    );

    if (!currentSelectedValue && values[field].value) {
      filteredResults.push({ label: values[field].text, value: values[field].value });
    }

    setSearchResults(filteredResults);
  };

  const fetchDirectories = async (searchTerm: string) => {
    if (enterpriseId) {
      if (requestDestinationsCancelTokenSource) {
        requestDestinationsCancelTokenSource.cancel();
      }
      requestDestinationsCancelTokenSource = CancelToken.source();
      setShowLoadingSpinner(true);
      const results = await dispatch(
        directoryOperations.searchDirectory(
          currentUser.currentEnterprise.id,
          requestDestinationsCancelTokenSource.token,
          {
            size: MAX_SEARCH_RESULTS,
            search: searchTerm || '',
            type: 'all'
          }
        )
      );
      if (results && results.results) {
        const filteredResults = results.results
          .filter(
            r =>
              r.publicInfo &&
              r.displayName &&
              isValidAddressNumber(r.publicInfo.addressNumber) &&
              r.publicInfo.addressNumber !== currentAddressNumber
          )
          .map(r => ({
            value: r.publicInfo.addressNumber,
            label: `${getServiceName(r.displayName) || ''} (${r.publicInfo.addressNumber})`
          }));
        addCurrentSelectedValueToSearchResults(filteredResults);
        setShowLoadingSpinner(false);
      }
    }
  };

  useEffect(() => {
    const fetchTargets = async () => {
      if (values[field].value) {
        fetchDirectories(values[field].value);
      }
    };
    fetchTargets();
    return () => {
      if (requestDestinationsCancelTokenSource) {
        requestDestinationsCancelTokenSource.cancel();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectItem = (value: string, text: string) => {
    const selectedItem = searchResults.find(item => item.value === value);
    if (selectedItem) {
      const addr = selectedItem.value || '';
      handleOnTargetSelected(addr, text);
    }
  };

  const handleOnChange = value => {
    setValue(
      field,
      {
        ...values[field],
        value: ''
      },
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );
    fetchDirectories(value);
  };

  return (
    <div className={styles['input-wrapper']}>
      {showLoadingSpinner && <LoadingSpinner className={styles['loading-spinner']} />}
      <Combobox
        id="search-by-internal-target"
        items={searchResults}
        className={styles['search-internal-destination']}
        selectedValue={values[field].value}
        onOptionsShow={() => {
          fetchDirectories('');
        }}
        i18n_combobox_errorMessage={errors && (errors.message || 'error')}
        onValueChange={value => handleOnChange(value)}
        onValueSelect={element => {
          onSelectItem(element.dataset.value, element.innerText);
        }}
      />
    </div>
  );
};

export default SearchInternalDestination;
