// @flow

import React, { type Element, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios';
import Combobox from '@design-system/component-library/src/components/Combobox';
import IconDeleteRegular from '@design-system/component-library/src/components/Icon/lib/IconDeleteRegular';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import Item from '../Item';
import styles from './CustomCallerID.module.scss';

export type PropsT = {
  enterpriseId: string,
  extensionId: string,
  hideCallerId: boolean,
  isSectionInEditMode: boolean,
  updateUserAccount: (*) => void,
  saveUserAccount: (*) => void,
  userAccount: *
};

let cancelGetCustomCallerIdsRequest: CancelTokenSource;
let cancelGetPstnPoolNumbersRequest: CancelTokenSource;

const CustomCallerID = ({
  enterpriseId,
  extensionId,
  hideCallerId,
  isSectionInEditMode,
  userAccount,
  updateUserAccount,
  saveUserAccount
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const [radioSelection, setRadioSelection] = useState('default');
  const [manualNumber, setManualNumber] = useState();
  const statuses = [
    'AVAILABLE',
    'WORKING',
    'BUSINESS_TRIP',
    'LUNCH',
    'CUSTOMER_MEETING',
    'TRAINING',
    'AWAY',
    'HOLIDAYS'
  ];
  const EMPTY = 'empty'; // setting some const for this, as any falsy value is not selectable with the component
  const [dropDownItems, setDropDownItems] = useState([]);
  const [customCallerIdsAllowed, setCustomCallerIdsAllowed] = useState([]);
  const [pstnPoolNumbers, setPstnPoolNumbers] = useState([]);
  const [searchKey, setSearchKey] = useState(0);
  const [pstnPoolNumbersSearchItems, setPstnPoolNumbersSearchItems] = useState([]);

  const getHtmlOption = (num, text: string, isDefault: boolean): Element<'span'> => {
    return (
      <span className={isDefault ? styles['dropdown-item-default'] : styles['dropdown-item']}>
        {num} <span className={styles['dropdown-text']}>{text}</span>
      </span>
    );
  };

  const parseUserAccountData = async () => {
    if (userAccount) {
      if (userAccount.customCallerIDChoice === 'ManualSetting') {
        if (userAccount.manualCustomCallerID) {
          setRadioSelection(userAccount.customCallerIDChoice);
        }
      } else {
        setRadioSelection(userAccount.customCallerIDChoice);
      }
      if (hideCallerId) {
        setRadioSelection('hidden');
      }
      setManualNumber(userAccount.manualCustomCallerID);
    }
  };

  const fetchCustomCallerIds = async () => {
    try {
      const dataReturned: AxiosPromise<> = await axios({
        method: 'GET',
        url: `/api/v2/enterprises/${enterpriseId}/customcallerids/${extensionId}`,
        cancelToken: cancelGetCustomCallerIdsRequest.token
      });
      setCustomCallerIdsAllowed(dataReturned.data.customCallerIds);
      return dataReturned.data.customCallerIds;
    } catch (error) {
      console.error(error);
    }
    return [];
  };

  const fetchPstnPoolNumbers = async callerIdsAllowed => {
    try {
      let results = [];
      let page = 1;
      const allResults = [];
      do {
        // eslint-disable-next-line no-await-in-loop
        const dataReturned: AxiosPromise<> = await axios({
          method: 'GET',
          url: `/api/v2/enterprises/${enterpriseId}/pstnpool`,
          cancelToken: cancelGetCustomCallerIdsRequest.token,
          params: { page }
        });
        results = dataReturned.data.results;
        allResults.push(...results);
        page++;
      } while (results.length > 0);
      setPstnPoolNumbers(allResults);
      setPstnPoolNumbersSearchItems(
        allResults
          .filter(
            number =>
              number.type === 'PstnNumber' &&
              !callerIdsAllowed.find(n => n.pstnNumber === number.pstnNumber) &&
              !(userAccount.extraCustomCallerIDList || []).includes(number.pstnNumber)
          )
          .map(number => ({
            id: number.pstnNumber,
            label: `${number.pstnNumber} ${number.userDisplayName || ''}`,
            value: number.pstnNumber
          }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const isNumberInUse = (number: string): boolean => {
    const { customCallerIDMapping, manualCustomCallerID } = userAccount;
    if (!customCallerIDMapping) {
      return false;
    }
    const found = Object.keys(customCallerIDMapping).find(
      status => customCallerIDMapping[status] === number
    );
    return !!found || manualCustomCallerID === number;
  };

  useEffect(() => {
    cancelGetCustomCallerIdsRequest = CancelToken.source();
    cancelGetPstnPoolNumbersRequest = CancelToken.source();
    const fetchTargets = async () => {
      await parseUserAccountData();
      const callerIdsAllowed = await fetchCustomCallerIds();
      await fetchPstnPoolNumbers(callerIdsAllowed);
    };

    fetchTargets();
    return () => {
      cancelGetCustomCallerIdsRequest.cancel();
      cancelGetPstnPoolNumbersRequest.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!userAccount) {
      return;
    }

    // form the set of available numbers in dropdown
    const defNumber =
      userAccount.pnNumbers && userAccount.pnNumbers.length > 0 ? userAccount.pnNumbers[0] : '';

    const defaultOption = {
      id: null,
      label: '',
      value: EMPTY,
      html: getHtmlOption(t('users.customCallerID.defaultOption'), defNumber, true)
    };

    let numbers = customCallerIdsAllowed.map(number => ({
      id: number.pstnNumber,
      label: `${number.pstnNumber} ${number.userDisplayName || ''}`,
      value: number.pstnNumber,
      html: getHtmlOption(number.pstnNumber, number.userDisplayName || '', false)
    }));

    const idList = (userAccount.extraCustomCallerIDList || [])
      .filter(number => {
        return !customCallerIdsAllowed.find(n => n.pstnNumber === number);
      })
      .map(number => {
        // find appropriate label from pstnPoolNumbers
        const pstnNum = pstnPoolNumbers.find(n => n.pstnNumber === number);
        const labelText = pstnNum && pstnNum.userDisplayName ? pstnNum.userDisplayName : '';

        return {
          id: number,
          label: labelText,
          value: number,
          html: getHtmlOption(number, labelText, false)
        };
      });

    numbers = [defaultOption, ...numbers, ...idList];
    setDropDownItems(numbers);
  }, [customCallerIdsAllowed, pstnPoolNumbers, userAccount, t]);

  const getCli = () => {
    if (hideCallerId) {
      return t('users.customCallerID.hiddenOption');
    }
    if (userAccount.customCallerIDChoice === 'ManualSetting' && userAccount.manualCustomCallerID) {
      return userAccount.manualCustomCallerID;
    }
    if (userAccount.customCallerIDChoice === 'FromPresenceState') {
      return t('users.customCallerID.presenceState');
    }
    return userAccount.callerId;
  };

  const fetchExtraCustomCallerIDList = idList => {
    setPstnPoolNumbersSearchItems(
      pstnPoolNumbers
        .filter(
          number =>
            number.type === 'PstnNumber' &&
            !(idList || userAccount.extraCustomCallerIDList || []).includes(number.pstnNumber)
        )
        .map(number => ({
          id: number.pstnNumber,
          label: `${number.pstnNumber} ${number.userDisplayName || ''}`,
          value: number.pstnNumber
        }))
    );
  };

  return isSectionInEditMode ? (
    <div className={`${styles['number-selection']} ${styles['margin-bottom-medium']}`}>
      <Combobox
        id="search_extra_number"
        className={styles['search-area']}
        label={t('users.customCallerID.additionalCallerIDLabel')}
        items={pstnPoolNumbersSearchItems}
        key={searchKey}
        i18n_combobox_errorMessage=""
        onValueSelect={async element => {
          if (!userAccount.extraCustomCallerIDList.includes(element.dataset.value)) {
            userAccount.extraCustomCallerIDList.push(element.dataset.value);
          }
          fetchExtraCustomCallerIDList();
          updateUserAccount({
            ...userAccount,
            extraCustomCallerIDList: userAccount.extraCustomCallerIDList
          });
          await saveUserAccount({
            ...userAccount,
            extraCustomCallerIDList: userAccount.extraCustomCallerIDList
          });
        }}
      />
      <div className={styles['margin-bottom-medium']}>
        {userAccount &&
          userAccount.extraCustomCallerIDList &&
          userAccount.extraCustomCallerIDList.map(number => (
            <div className={styles['number-item']} key={`number-item-${number}`}>
              {number}
              {!isNumberInUse(number) && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <span
                  onClick={async () => {
                    setCustomCallerIdsAllowed(
                      customCallerIdsAllowed.filter(n => {
                        return n.pstnNumber !== number;
                      })
                    );
                    const idList = userAccount.extraCustomCallerIDList.filter(id => id !== number);

                    fetchExtraCustomCallerIDList(idList);
                    updateUserAccount({
                      ...userAccount,
                      extraCustomCallerIDList: idList
                    });
                    await saveUserAccount({
                      ...userAccount,
                      extraCustomCallerIDList: idList
                    });

                    setSearchKey(new Date().getTime());
                  }}
                  className={styles['delete-button']}
                >
                  <IconDeleteRegular size="m" color="danger" />
                </span>
              )}
            </div>
          ))}
      </div>
      <RadioGroup label={t('users.customCallerID.selectCallerIDLabel')} value={radioSelection}>
        <Radio
          id="custom-caller-id-option-1"
          name="custom-caller-id-option"
          onChange={() => {
            setRadioSelection('default');
            updateUserAccount({
              ...userAccount,
              manualCustomCallerID: '',
              hideCallerId: false,
              customCallerIDChoice: 'ManualSetting'
            });
          }}
          label={`${t('users.customCallerID.defaultOption')} ${
            userAccount && userAccount.pnNumbers && userAccount.pnNumbers.length > 0
              ? userAccount.pnNumbers[0]
              : t('users.customCallerID.noAvailableNumber')
          }`}
          value="default"
        />
        <Radio
          id="custom-caller-id-option-2"
          name="custom-caller-id-option"
          label={t('users.customCallerID.manualOption')}
          disabled={!dropDownItems || dropDownItems.length === 0}
          onChange={() => {
            setRadioSelection('ManualSetting');
            updateUserAccount({
              ...userAccount,
              hideCallerId: false,
              customCallerIDChoice: 'ManualSetting'
            });
          }}
          value="ManualSetting"
        />
        <Radio
          id="custom-caller-id-option-3"
          name="custom-caller-id-option"
          label={t('users.customCallerID.presenceOption')}
          onChange={() => {
            setRadioSelection('FromPresenceState');
            updateUserAccount({
              ...userAccount,
              hideCallerId: false,
              customCallerIDChoice: 'FromPresenceState',
              manualCustomCallerID: ''
            });
          }}
          value="FromPresenceState"
        />
        <Radio
          id="custom-caller-id-option-4"
          name="custom-caller-id-option"
          label={t('users.customCallerID.hiddenOption')}
          onChange={() => {
            updateUserAccount({
              ...userAccount,
              manualCustomCallerID: '',
              hideCallerId: true
            });
            setRadioSelection('hidden');
          }}
          value="hidden"
        />
      </RadioGroup>
      {radioSelection === 'ManualSetting' && (
        <Dropdown
          id="manual_number_dropdown"
          className={styles['number-dropdown']}
          items={dropDownItems.filter(e => e.value !== EMPTY)}
          optional
          selectedValue={manualNumber}
          onValueChange={element => {
            setManualNumber(element.dataset.value);
            updateUserAccount({
              ...userAccount,
              manualCustomCallerID: element.dataset.value,
              hideCallerId: false,
              customCallerIDChoice: 'ManualSetting'
            });
          }}
        />
      )}
      {radioSelection === 'FromPresenceState' && (
        <div>
          {statuses.map(status => (
            <div className={styles['presence-item']} key={`presence_search_number_${status}`}>
              <div className={styles['presence-name']}>
                {t(`forwardingDetails.statusSelector.${status}`)}
              </div>
              <Dropdown
                id={`presence_search_number_${status}`}
                className={styles['number-dropdown']}
                items={dropDownItems}
                optional
                selectedValue={
                  userAccount.customCallerIDMapping
                    ? userAccount.customCallerIDMapping[status]
                    : EMPTY
                }
                onValueChange={element => {
                  let { customCallerIDMapping } = userAccount;
                  if (element.dataset.value && element.dataset.value !== EMPTY) {
                    customCallerIDMapping = {
                      ...customCallerIDMapping,
                      [status]: element.dataset.value
                    };
                    // $FlowFixMe
                    if (status === 'AWAY') {
                      customCallerIDMapping = {
                        ...customCallerIDMapping,
                        SICK: element.dataset.value
                      };
                    }
                  } else {
                    delete customCallerIDMapping[status];
                    // $FlowFixMe
                    if (status === 'AWAY') delete customCallerIDMapping.SICK;
                  }
                  updateUserAccount({
                    ...userAccount,
                    customCallerIDMapping
                  });
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div>
      <Item label={t('users.customCallerID.clidLabel')} id="clidNumber" value={getCli()} />
    </div>
  );
};
export default CustomCallerID;
