// @flow strict-local

import React, { type Element } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import ContentTabs from '../../components/ContentTabs/ContentTabs';
import Tab from '../../components/ContentTabs/Tab';
import BaseContainer from '../BaseContainer/BaseContainer';
import CallFlowServices from './CallFlowServices';
import {
  goToCallFlowCalendars,
  goToCallFlowServices,
  goToCallFlowImport,
  goToCalendarTemplates
} from '../../navigationOperations';
import Calendars from './calendar/Calendars';
import CalendarTemplates from './calendarTemplates/CalendarTemplates';
import Notifications from '../../components/Notifications/Notifications';
import ImportServices from './importServices/ImportServices';
import TopNavigation from '../navigation/TopNavigation';
import { createSetStepsAction, createStartTourAction } from '../../ducks/ui/tour/tourActions';
import styles from './CallFlows.module.scss';

type CallFlowTabT = 'services' | 'calendars' | 'calendartemplates' | 'import-services';

type PropsT = {|
  match: {
    params: {
      id: string,
      tab?: CallFlowTabT
    }
  }
|};

export const CallFlows = (props: PropsT): Element<typeof BaseContainer> => {
  // eslint-disable-next-line react/destructuring-assignment
  const { id: enterpriseId, tab: activeTab } = props.match.params;

  // redux
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const calendarsOption = (currentUser.featureFlags || []).includes(
    'FEATURE-HIDE-CALLFLOW-TAB-CALENDAR'
  )
    ? []
    : [{ id: 'calendars', name: t('callflows.tabs.calendars') }];

  /* TODO: uncomment when ready for production
  const calendarTemplatesOption = (currentUser.featureFlags || []).includes(
    'FEATURE-HIDE-CALLFLOW-TAB-CALENDAR'
  )
    ? []
    : [{ id: 'calendartemplates', name: t('callflows.tabs.calendarTemplates') }];
  */

  const showImportFeature = currentUser
    ? !(currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-IMPORT')
    : false;

  const importOption =
    currentUser.multiEnterpriseAdmin && showImportFeature
      ? [{ id: 'import-services', name: t('callflows.tabs.importServices') }]
      : [];

  const tabOptions = [
    { id: 'services', name: t('callflows.tabs.services') },
    ...calendarsOption,
    /* ...calendarTemplatesOption, TODO: uncomment when ready for production */
    ...importOption
  ];

  const showTour = (): void => {
    dispatch(
      createSetStepsAction([
        {
          disableBeacon: true,
          title: t('tours.callflow.step1.title'),
          content: t('tours.callflow.step1.text'),
          target: '#callflowsArea',
          placement: 'top'
        },
        {
          title: t('tours.callflow.step2.title'),
          content: t('tours.callflow.step2.text'),
          target: '#callflows-category-field',
          placement: 'auto'
        },
        {
          title: t('tours.callflow.step3.title'),
          content: t('tours.callflow.step3.text'),
          target: '#calendars-tab-button',
          placement: 'auto'
        },
        {
          title: t('tours.callflow.step4.title'),
          content: t('tours.callflow.step4.text'),
          target: '#searchViewMode',
          placement: 'auto'
        }
      ])
    );
    dispatch(createStartTourAction());
  };

  const updateActiveTab = (selectedTab: string) => {
    if (selectedTab === 'calendars') {
      dispatch(goToCallFlowCalendars(enterpriseId));
    } else if (selectedTab === 'services') {
      dispatch(goToCallFlowServices(enterpriseId));
    } else if (selectedTab === 'calendartemplates') {
      dispatch(goToCalendarTemplates(enterpriseId));
    } else if (selectedTab === 'import-services') {
      dispatch(goToCallFlowImport(enterpriseId));
    }
  };

  return (
    <BaseContainer
      header={
        <>
          <TopNavigation onClickTutorial={showTour} viewName="callFlows" />
          <Notifications
            tags={[
              'calendar-delete-fail',
              'calendar-delete-success',
              'calendar-edit-fail',
              'calendar-edit-success',
              'calendar-create-fail',
              'calendar-create-success',
              'callflow-edit-fail',
              'callflow-edit-success'
            ]}
          />
        </>
      }
    >
      <ContentTabs
        activeTab={activeTab || 'services'}
        tabOptions={tabOptions}
        updateActiveTab={updateActiveTab}
        tabSelectorStyle={styles['tab-selector']}
      >
        <Tab id="services">
          <CallFlowServices enterpriseId={enterpriseId} />
        </Tab>
        <Tab id="calendars">
          <Calendars enterpriseId={enterpriseId} />
        </Tab>
        <Tab id="calendartemplates">
          <CalendarTemplates enterpriseId={enterpriseId} />
        </Tab>
        <Tab id="import-services">
          <ImportServices enterpriseId={enterpriseId} />
        </Tab>
      </ContentTabs>
    </BaseContainer>
  );
};

export default CallFlows;
