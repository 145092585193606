// @flow
import type { CurrentUserT, CurrentUserStateT } from '../ducks/currentUser/currentUserTypes';

export const isSingleEnterpriseAdmin = (currentUser: ?CurrentUserT | CurrentUserStateT) => {
  if (currentUser && !currentUser.multiEnterpriseAdmin) {
    return (currentUser.roles || []).includes('LOGGED_AS_ADMIN');
  }
  return false;
};

export const isMultiEnterpriseAdmin = (currentUser: ?CurrentUserT | CurrentUserStateT) => {
  return !!(currentUser && currentUser.multiEnterpriseAdmin);
};

export const isAdmin = (currentUser: ?CurrentUserT | CurrentUserStateT) => {
  return isMultiEnterpriseAdmin(currentUser) || isSingleEnterpriseAdmin(currentUser);
};

export const isElisaAdmin = (currentUser: ?CurrentUserT | CurrentUserStateT) => {
  return (
    isMultiEnterpriseAdmin(currentUser) &&
    currentUser &&
    currentUser.loginType === 'BEHALF_OF_ELISA_AD'
  );
};

export const isAcdManager = (currentUser: ?CurrentUserT | CurrentUserStateT) => {
  return currentUser
    ? (currentUser.roles || []).includes('RING_USER_ACD_MANAGER') ||
        (currentUser.roles || []).includes('RING_USER_ACD_MANAGER_RESTRICTED_ACD')
    : false;
};

export const isServiceProvider = (currentUser: ?CurrentUserT | CurrentUserStateT) => {
  return currentUser && currentUser.domainType === 'Service Provider';
};

export const isSwitchboardUser = (currentUser: ?CurrentUserT | CurrentUserStateT) => {
  return currentUser ? (currentUser.roles || []).includes('RING_USER_SWITCHBOARD') : false;
};

export const createCsrfHeader = (currentUser: ?CurrentUserT | CurrentUserStateT) => {
  return currentUser
    ? {
        'X-CSRF-TOKEN': currentUser.csrf
      }
    : {};
};
